import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/vehicles";
const STORE_NAME = "documents";

export const fetchVehicleDocuments = async (vehicleId) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/documents/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const addVehicleDocuments = async (vehicleId, item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/documents/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to add ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to add ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot upload documents offline.`,
    });
  }
};

export const deleteVehicleDocuments = async (vehicleId, item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/documents/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot delete documents offline.`,
    });
  }
};
