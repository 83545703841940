import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/vehicles";
const STORE_NAME = "vehicles";

export const fetchAndStoreVehicles = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        // Clear old data and store new data
        await db.clearStore(STORE_NAME);
        data.forEach((item) => db.addItem(STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(STORE_NAME);
  }
};

export const getVehicle = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to get ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(STORE_NAME, id);
  }
};

export const addVehicle = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreVehicles();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to create ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to create ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "addVehicle", STORE_NAME, item });
    await db.addItem(STORE_NAME, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const editVehicle = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/edit`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreVehicles();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "editVehicle", STORE_NAME, item });
    await db.editItem(STORE_NAME, item.uuid, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const deleteVehicle = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreVehicles();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "deleteVehicle", STORE_NAME, item });
    await db.deleteItem(STORE_NAME, item.uuid);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const syncOfflineVehiclesChanges = async () => {
  if (!navigator.onLine) return;

  const offlineChanges = await db.getOfflineChanges();
  for (const change of offlineChanges) {
    store.commit("startSync", true);
    try {
      if (change.type === "addVehicle") {
        await addVehicle(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "editVehicle") {
        await editVehicle(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "deleteVehicle") {
        await deleteVehicle(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }

      store.dispatch("endSync");
    } catch (error) {
      console.error(`Failed to sync change for ${change.STORE_NAME}:`, error);
    }
  }
};

export const updateEmission = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/emission-test`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update Emission test date: ${error}`,
      });
      console.error(`Failed to update Emission test date:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update Emission test date offline.`,
    });
  }
};

export const updateInsurance = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/insurance`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update Insurance date: ${error}`,
      });
      console.error(`Failed to update Insurance date:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update Insurance date offline.`,
    });
  }
};

export const updateLicense = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/revenue-license`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update Revenue License date: ${error}`,
      });
      console.error(`Failed to update Revenue License date:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update Revenue License date offline.`,
    });
  }
};

export const oilChange = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/oil-change`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update oil change information: ${error}`,
      });
      console.error(`Failed to update oil change information:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update oil change information offline.`,
    });
  }
};

export const mechanicalService = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/mechanical-service`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update oil change information: ${error}`,
      });
      console.error(`Failed to update oil change information:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update oil change information offline.`,
    });
  }
};

export const changeDriver = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/driver`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update driver: ${error}`,
      });
      console.error(`Failed to update driver:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update driver offline.`,
    });
  }
};

export const enableVehicle = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/enable`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to enable vehicle: ${error}`,
      });
      console.error(`Failed to enable vehicle:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot enable vehicle offline.`,
    });
  }
};

export const disableVehicle = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/disable`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to disable vehicle: ${error}`,
      });
      console.error(`Failed to disable vehicle:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot disable vehicle offline.`,
    });
  }
};
